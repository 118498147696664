import classNames from "classnames";
import { useRouter } from "next/router";
import React from "react";

interface ActionButtonProps {
  href?: string
  className?: string
  children: string
  enabled: boolean
  onClick?: () => void
  type?: "submit" | "button"
}

export default function ActionButton({ onClick, type, enabled, className, href, children }: ActionButtonProps) {
  const router = useRouter();

  function handleClick(e: React.MouseEvent<HTMLButtonElement>) {
    if (onClick) {
      onClick();
    }

    if (enabled && href) {
      router.push(href);
    }
  }
  const classes: any = {
    "disabled": !enabled,
    "action-button": true,
  }
  if (className) {
    classes[className] = true
  }
  const buttonClassNames = classNames(classes)
  return (
    <button type={type} disabled={!enabled} className={buttonClassNames} onClick={handleClick}>
      {children}
    </button>
  );
}
